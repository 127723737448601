<template>
  <router-link
    :to="path"
    class="returnBtn">
    一覧に戻る
  </router-link>
</template>

<script>
export default {
  name: 'ReturnToIndexBtn',
  props: {
    path: String,
  },
}
</script>
<style lang="scss" scoped>
.returnBtn {
  font-size: 2.2rem;
  color: $orColor;
  font-weight: bold;
  width: 360px;
  height: 80px;
  border: 1px solid $orColor;
  border-radius: 40px;
  margin: auto;
  display: block;
  text-align: center;
  line-height: 80px;
  &:hover {
    background: $orColor;
    color: #fff;
    border-color: transparent;
  }
}
@media only screen and (max-width: 768px) {
  .returnBtn {
    width: 210px;
    height: 50px;
    font-size: 1.4rem;
    line-height: 50px;
  }
}
</style>
